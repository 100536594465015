<template>
  <div class="freshdeskcopy">
    <div class="copy">
      <span class="title is-6">Email Template to copy</span>
      <button class="button is-small" v-on:click="copyToClipboard('freshDeskCopy' + type)">Copy</button>
    </div>
    <div class="copybody">
      <div :id="'freshDeskCopy' + type">
        <slot name="header"></slot>
        <br />
        <fulfilment-info-for-bureau :fulfilment="fulfilment" v-if="!hideTable"></fulfilment-info-for-bureau>

        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
    
<script>
import { baseMixin } from "../../../mixins/baseMixin.js";

export default {
  components: {
    fulfilmentInfoForBureau: () => import("./fulfilmentInfoForBureau.vue")
  },
  mixins: [baseMixin],
  props: {
    fulfilment: {
      type: Object,
      required: true
    },
    hideTable: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: true
    }
  }
};
</script>
